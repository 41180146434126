import api from './_config'

export default {
  getSearchItems({
    take,
    searchType,
    activeCategories = [],
    terms = [],
    language = null
  }, { skip }) {
    const params = {
      skip,
      take,
      terms,
      language
    }
    if (searchType === 'Recipe') {
      params.categories = activeCategories.filter((x) => x.uid.startsWith('rdb')).map((category) => category.uid)
      params.tags = activeCategories.filter((x) => x.uid.startsWith('tdb')).map((tag) => tag.uid)
    } else {
      params.tags = activeCategories.map((category) => category.uid)
    }

    return api.get(`/api/content/${language}/${searchType}/search/`, { params })
  }
}
