const firedEvents = new Set()

export default {
  // search functionality
  startSearchTyping() {
    const gtmObj = {
      event: 'searchStartTyping'
    }
    this.pushDataLayer(gtmObj)
  },
  searchTerm(term) {
    const gtmObj = {
      event: 'searchCommence',
      searchText: term
    }
    this.pushDataLayer(gtmObj)
  },
  addFilter() {
    const gtmObj = {
      event: 'filterSelect'
    }
    this.pushDataLayer(gtmObj)
  },
  removeFilter(name) {
    const gtmObj = {
      event: 'filterRemove',
      filterText: name
    }
    this.pushDataLayer(gtmObj)
  },
  removeAllFilters() {
    const gtmObj = {
      event: 'filterClearAll'
    }
    this.pushDataLayer(gtmObj)
  },
  // accordion
  accordionOpen(title) {
    const gtmObj = {
      event: 'accordionOpen',
      accordionTitle: title
    }
    this.pushDataLayer(gtmObj)
  },
  accordionClose(title) {
    const gtmObj = {
      event: 'accordionClose',
      accordionTitle: title
    }
    this.pushDataLayer(gtmObj)
  },
  // fundamentals
  fundamentalsStart() {
    const gtmObj = {
      event: 'fundamentalsStart'
    }
    this.pushDataLayer(gtmObj)
  },
  fundamentalsClose() {
    const gtmObj = {
      event: 'fundamentalsClose'
    }
    this.pushDataLayer(gtmObj)
  },
  fundamentalsNextSlide() {
    const gtmObj = {
      event: 'fundamentalsNextSlide'
    }
    this.pushDataLayer(gtmObj)
  },
  fundamentalsPreviousSlide() {
    const gtmObj = {
      event: 'fundamentalsPreviousSlide'
    }
    this.pushDataLayer(gtmObj)
  },
  // sliders
  standardSliderSlide(direction) {
    const gtmObj = {
      event: 'standardCardCarouselInteraction',
      direction
    }
    this.pushDataLayer(gtmObj)
  },
  circleSliderSlide(direction) {
    const gtmObj = {
      event: 'circleCardCarouselInteraction',
      direction
    }
    this.pushDataLayer(gtmObj)
  },
  mediaCardSliderSlide(direction) {
    const gtmObj = {
      event: 'mediaCardCarouselInteraction',
      direction
    }
    this.pushDataLayer(gtmObj)
  },
  productCardSliderSlide(direction) {
    const gtmObj = {
      event: 'productCardCarouselInteraction',
      direction
    }
    this.pushDataLayer(gtmObj)
  },
  videoStart(heading) {
    const gtmObj = {
      event: 'videoStart',
      videoTitle: heading
    }
    this.pushDataLayer(gtmObj)
  },
  videoComplete(heading) {
    const gtmObj = {
      event: 'videoComplete',
      videoTitle: heading
    }
    this.pushDataLayer(gtmObj)
  },
  videoProgress(heading, duration) {
    const gtmObj = {
      event: 'videoProgress',
      videoPercent: `${duration}%`,
      videoTitle: heading
    }
    this.pushDataLayer(gtmObj)
  },
  videoAutoplay(heading) {
    const gtmObj = {
      event: 'videoAutoplay',
      videoTitle: heading
    }
    this.pushDataLayer(gtmObj)
  },
  formSubmitted() {
    const gtmObj = {
      event: 'formSubmit'
    }
    this.pushDataLayer(gtmObj)
  },
  recipeShare() {
    const gtmObj = {
      event: 'recipeShare'
    }
    this.pushDataLayer(gtmObj)
  },
  recipePrint() {
    const gtmObj = {
      event: 'recipePrint'
    }
    this.pushDataLayer(gtmObj)
  },
  scaleRecipePortions(portions, type) {
    this.pushDataLayer({
      event: 'portionsField',
      placement: 'recipe ingredients',
      portions,
      type
    })
  },
  visibilityTracker(selector, event) {
    const targetBlock = document.querySelector(selector)
    let visibilityTimeout = null

    if (targetBlock) {
      const handleVisibility = (entries) => {
        const [entry] = entries
        if (entry.isIntersecting && !firedEvents.has(event)) {
          visibilityTimeout = setTimeout(() => {
            if (!firedEvents.has(event)) {
              this.pushDataLayer({ event })
              firedEvents.add(event)
            }
          }, 10000)
        } else if (!entry.isIntersecting && visibilityTimeout) {
          clearTimeout(visibilityTimeout)
        }
      }

      const observer = new IntersectionObserver(handleVisibility, {
        threshold: 0.5
      })

      observer.observe(targetBlock)
    }
  },
  viewRecipeIngredients10s() {
    this.visibilityTracker('.c-recipe-ingredients', 'viewRecipeIngredients10s')
  },
  viewRecipeInstructions10s() {
    this.visibilityTracker('.c-recipe-preparation', 'viewRecipeInstructions10s')
  },
  pushDataLayer(obj) {
    if (window.dataLayer) {
      window.dataLayer.push(obj)
    }
  }
}
