import '@/main.scss'
import '@/assets/assets'
import 'video.js/dist/video-js.css'
import vueUtil from '@/utils/vue-util'
import navigationHandler from '@/components/handlers/navigation-handler'
import formHandler from '@/components/handlers/form-handler'
import addClassIfSmallScreen from '@/utils/AddClassMobiles'
import tracking from '@/utils/gtm-util'

// navigation
import ShareButton from '@/components/navigation/ShareButton.vue'
import PrintButton from '@/components/navigation/PrintButton.vue'
import FeaturedContent from '@/components/navigation/FeaturedContent.vue'
import RecipeMobileNavigation from '@/components/navigation/RecipeMobileNavigation.vue'
import RecipeDesktopNavigation from '@/components/navigation/RecipeDesktopNavigation.vue'

// blocks
import SplitBlock from '@/components/blocks/SplitBlock.vue'
import FundamentalBlock from '@/components/blocks/FundamentalBlock.vue'
import StandardSlider from '@/components/blocks/StandardSlider.vue'
import MediaStepsSlider from '@/components/blocks/MediaStepsSlider.vue'
import MediaCardsSlider from '@/components/blocks/MediaCardsSlider.vue'
import CircleCardSlider from '@/components/blocks/CircleCardSlider.vue'
import ProductSlider from '@/components/blocks/ProductsSlider.vue'

// shared
import VideoBlock from '@/components/Video.vue'
import Accordion from '@/components/Accordion.vue'
import Modal from '@/components/Modal.vue'
import ScaleHeading from '@/components/ScaleHeading.vue'
import MediaCardItem from '@/components/MediaCardItem.vue'

// recipes
import SearchOverview from '@/components/search/SearchOverview.vue'

// recipe
import RecipeIngredients from '@/components/recipe/RecipeIngredients.vue'
import RecipeTip from '@/components/recipe/RecipeTip.vue'
import RecipeQuestion from '@/components/recipe/RecipeQuestion.vue'
import RecipeCookMode from '@/components/recipe/RecipeCookMode.vue'

// forms
import InvisibleRecaptchaElement from '@/components/formElements/InvisibleRecaptchaElement.vue'

const components = {
  // navigation
  ShareButton,
  PrintButton,
  FeaturedContent,
  RecipeMobileNavigation,
  RecipeDesktopNavigation,

  // blocks
  SplitBlock,
  FundamentalBlock,
  StandardSlider,
  MediaStepsSlider,
  MediaCardsSlider,
  CircleCardSlider,
  ProductSlider,

  // shared
  Accordion,
  VideoBlock,
  Modal,
  ScaleHeading,
  MediaCardItem,

  // search
  SearchOverview,

  // recipe
  RecipeIngredients,
  RecipeTip,
  RecipeQuestion,
  RecipeCookMode,

  // forms
  InvisibleRecaptchaElement
}

vueUtil.mountComponents(components)

navigationHandler.init('.js-top-nav', 'nav-open')
navigationHandler.initTopNav(
  '.js-top-nav-menu',
  '.js-menu',
  '#nav-content'
)
navigationHandler.initMenu(
  '.js-menu-close',
  '.js-menu-list li.has-children',
  '.js-item-toggle',
  'expanded'
)
navigationHandler.initMarketSelector(
  '.js-market-selector-open',
  '.js-market-selector-close',
  '.js-markets',
  'market-selector-open'
)

formHandler.init()

const scrollbarWidth = window.innerWidth - document.body.clientWidth
document.documentElement.style.setProperty('--scrollbarWidth', `${scrollbarWidth}px`)
addClassIfSmallScreen('.c-item-recipe-hero__inner-heading', 'adjustable-fontsize')
tracking.viewRecipeInstructions10s()
